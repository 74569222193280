import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActiveCommentInterface } from '../types/activeComment.interface';
import { ActiveCommentTypeEnum } from '../types/activeCommentType.enum';
import { CommentInterface } from '../types/commentInterface';


@Component({
  selector: 'comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() comment!: CommentInterface;
  @Input() activeComment!: ActiveCommentInterface | null;
  @Input() replies!: CommentInterface[];
  @Input() currentUserId!: number;
  @Input() parentId!: number | null;

  @Output()
  setActiveComment = new EventEmitter<ActiveCommentInterface | null>();
  @Output()
  deleteComment = new EventEmitter<number>();
  @Output()
  addComment = new EventEmitter<{ text: string; files?: any[]; parentId: number | null }>();
  @Output()
  updateComment = new EventEmitter<{ text: string; files?: any[]; commentId: number }>();
 

  createdAt: string = '';
  canReply: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  activeCommentType = ActiveCommentTypeEnum;
  replyId: number | null = null;

  ngOnInit(): void {
    const fiveMinutes = 300000;
    const timePassed = new Date().getMilliseconds() - new Date(this.comment.inserted_date).getMilliseconds() > fiveMinutes;
    this.createdAt = new Date(this.comment.inserted_date).toLocaleDateString();
    this.canReply = Boolean(this.currentUserId);
    this.canEdit = this.currentUserId === this.comment.user_id
    this.canDelete = this.currentUserId === this.comment.user_id &&
                     this.checkReplies(this.replies) === false
    this.replyId = this.parentId ? this.parentId : this.comment.id;
  }

  checkReplies(replies): boolean {
    return replies.filter(reply => reply.user_id !== this.currentUserId).length > 0
  }

  isReplying(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id === this.comment.id &&
      this.activeComment.type === this.activeCommentType.replying
    );
  }

  isEditing(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id === this.comment.id &&
      this.activeComment.type === 'editing'
    );
  }

  getIconClass(fileName: string): string {
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    switch (fileExtension) {
      case 'pdf':
        return 'fas fa-file-pdf spaced-icon pdf-icon'
      case 'doc':
      case 'docx':
        return 'fas fa-file-word spaced-icon word-icon'
      case 'xls':
      case 'xlsx':
        return 'fas fa-file-excel spaced-icon excel-icon'
      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'pjpeg': // Additional MIME type for JPEG
      case 'gif':
        return 'fas fa-file-image spaced-icon image-icon'
      // Add more cases for other file extensions as needed
      default:
        return 'fas fa-file spaced-icon default-icon' // Default icon for unknown file types
    }
  }

  handleEventFromChild(data: string) {
    console.log('Received from child:', data);
    // Here, you can call the method or perform any action you want in the parent component.
  }
}
