<mat-card class="calendar-header-card">
  <mat-card-header>
    <mat-card-title class="calendar-title">
      <div>
        <i mat-card-avatar class="material-symbols-outlined">lists</i><span>Calendar</span>
      </div>

    </mat-card-title>
  </mat-card-header>
</mat-card>

<br />

<div [ngSwitch]="view" id="view-body">
  <mat-card class="calendar-header">
  <mat-button-toggle-group>
    <mat-button-toggle
    mwlCalendarToday
    [(viewDate)]="viewDate"
    id = "today"
    >
    Today
    </mat-button-toggle>

    <mat-button-toggle
      mwlCalendarPreviousView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="closeOpenMonthViewDay()"
    >
      <span class="material-symbols-outlined">
        arrow_back_ios
      </span>
    </mat-button-toggle>

    <div>
      <h4>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h4>
    </div>

    <mat-button-toggle
      mwlCalendarNextView
      [view]="view"
      [(viewDate)]="viewDate"
      (viewDateChange)="closeOpenMonthViewDay()"
    >
      <span class="material-symbols-outlined">
        arrow_forward_ios
      </span>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div id = "legend">
    <span id="license-legend"></span>
    <span>Licensing</span>
  </div>
  <mat-button-toggle-group>
      <mat-button-toggle
        (click)="setView(CalendarView.Month)"
        [class.active]="view === CalendarView.Month"
      >
        Month
      </mat-button-toggle>
      <mat-button-toggle
        (click)="setView(CalendarView.Week)"
        [class.active]="view === CalendarView.Week"
      >
        Week
      </mat-button-toggle>
      <mat-button-toggle
        (click)="setView(CalendarView.Day)"
        [class.active]="view === CalendarView.Day"
      >
        Day
      </mat-button-toggle>
  </mat-button-toggle-group>
</mat-card>

<mwl-calendar-month-view
  *ngSwitchCase="CalendarView.Month"
  [viewDate]="viewDate"
  [events]="events"
  [refresh]="refresh"
  [activeDayIsOpen]="activeDayIsOpen"
  [tooltipTemplate]="tooltipContent"
  (dayClicked)="dayClicked($event.day)"
>
</mwl-calendar-month-view>
<mwl-calendar-week-view
  *ngSwitchCase="CalendarView.Week"
  [viewDate]="viewDate"
  [events]="events"
  [refresh]="refresh"
  [tooltipTemplate]="tooltipContent"
>
</mwl-calendar-week-view>
<mwl-calendar-day-view
  *ngSwitchCase="CalendarView.Day"
  [viewDate]="viewDate"
  [events]="events"
  [refresh]="refresh"
  [tooltipTemplate]="tooltipContent"
>
</mwl-calendar-day-view>
</div>
<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #tooltipContent let-event="event">
  <div class="tooltip">
    <div>{{event.title}}</div>
    <div>Created {{event.date}} [{{event.user}}]</div>
    <div>Time: {{event.time}}</div>
  </div>
  
</ng-template>