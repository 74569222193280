<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center">
    <img [src]="imageUrl$ | async" class="w-6 select-none flex-none">
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">{{ title$ | async }}</h2>

  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
