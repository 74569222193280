<mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="isLoading"></mat-progress-spinner>
<gallery gallerize 
         [items]="GalleryItems" 
         style="background-color:rgba(0, 0, 0, 0)" 
         [thumb]="false"
>               
</gallery>
<br>
<div class="bottom-strip">
    <div class="file-upload">
        <div (drop)="onFileDrop($event)" (dragover)="onDragOver($event)" vexFileDragNDrop>
            <label for="image" class="drag-and-drop-text">
                <img src="../../../assets/img/item-detail/real-upload.png" class="drag-and-drop">
                <input type="file" name="image" id="image" style="cursor: pointer;"
                    (change)="onFileChange($event.target.files)" multiple accept="image/*">
                <b>Drop Your Items or </b><span class="browse">Browse</span>
                <span *ngIf="this.imageFiles.length" [matBadge]="this.imageFiles.length" matBadgeOverlap="false"></span>
            </label>
        </div>
        <div>
            <section style="display: flex; gap: 5%;">
                <button mat-raised-button color="primary" style="width: 100%; margin-bottom: 2%; margin-top: 2%;"
                    (click)="onFileSave()">
                    {{isLoading ? 'Uploading...' : 'Save'}}
                </button>
            </section>
        </div>
    </div>
    <div class="thumbnail-strip">
        <carousel loop="false">
            <ng-container *ngFor="let image of imageSliderObject; index as i">
                <div class="carousel-cell">
                    <div class="image-container">
                        <img src="{{ image.thumbImage }}" />
                        <button *ngIf="image.thumbImage !== noImage" class="delete-image-button"
                            (click)="deleteImage(image.uuid, i)">Delete</button>
                        <button *ngIf="image.uuid !== primaryImage && image.thumbImage !== noImage" class="set-primary-image"
                        (click)="changePrimary(image.uuid)">Set as primary</button>
                    </div>
                </div>
            </ng-container>
        </carousel>
    </div>
</div>