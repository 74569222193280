import { Component, Input } from '@angular/core';

@Component({
  selector: 'view-notes-dialog',
  templateUrl: './view-notes-dialog.component.html',
  styleUrls: ['./view-notes-dialog.component.scss']
})

export class ViewNotesDialogComponent  {
  commentText: string;
  userInfo:any;
  comments:[] = []
  @Input() itemId: string;
  
  constructor() {}
}
