<div class="comments">
    <mat-progress-spinner mode="indeterminate" diameter="50" *ngIf="loading"></mat-progress-spinner>
    <comment-form 
        submitLabel="Post"
        (handleSubmit)="addComment({text: $event.text, files: $event.files, parentId: null})"
    ></comment-form>
    <div class="comments-container">
      <comment *ngFor="let comment of getRootComments()" 
                [comment]="comment"
                [replies]="getReplies(comment.id)"
                [currentUserId]="currentUserId"
                [activeComment]="activeComment"
                (setActiveComment)="setActiveComment($event)"
                (addComment)="addComment($event)"
                (updateComment)="updateComment($event)"
                (deleteComment)="deleteComment($event)"
      ></comment>
    </div>
  </div>