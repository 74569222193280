

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { itemService } from '../../../../helpers/services/main.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ItemDetailService } from '../../../../helpers/services/item_detail.service'

export interface DialogData {
  StatusController: any;
  FeedbackController: any;
  ApprovalController: any;
}
@Component({
  selector: 'feedback_dialog',
  templateUrl: 'feedback_dialog.component.html',
  styleUrls: ['feedback_dialog.component.scss'],

})
export class FeedbackDialogComponent {
  license_dropdown_data: any[] = [{}]
  form: FormGroup;
  selectedOption: any
  package = false;
  license = true;
  fromData: any[] = ["POP"]
  dateModifiedControler = new FormControl({ value: Date, disabled: false });
  FeedbackController = new FormControl({ value: String, disabled: false });
  StatusController = new FormControl({ value: String, disabled: false });
  ApprovalController = new FormControl({ value: String, disabled: false });
  FromController = new FormControl({ value: String, disabled: false });
  packageController = new FormControl({ value: Boolean, disabled: false });
  licenseController = new FormControl({ value: Boolean, disabled: false });
  constructor(private itemService: itemService, public dialogRef: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private _snakcbar: MatSnackBar, private fb: FormBuilder, public ItemDetailService: ItemDetailService,) {
  }

  async ngOnInit() {
    this.fromData.push(this.data.selectedLicensor)
    this.form = this.fb.group({
      FeedbackController: ["", Validators.required],
      StatusController: ["", Validators.required],
      //ApprovalController: ["", Validators.required],
      dateModifiedControler: [new Date(), Validators.required],
      FromController: ["", Validators.required],
      packageController: [this.package, Validators.required],
      licenseController: [this.license, Validators.required]
    });




  }

  async onSelectFrom(from){
    await this.ItemDetailService.getLicenseFeedBacks({from:from}).subscribe(item => {
      for (var i = 0; this.data.length > i; i++) {
        for (let k = 0; item.length > k; k++) {
          if (this.data[i].status == "Request Pre-Pro Sample" && item[k].status == "Request Pre-Pro Sample") {
            item[k].disable = true;
          } else if (this.data[i].status == "Pre-Pro Submitted" && item[k].status == "Pre-Pro Submitted") {
            item[k].disable = true;
          } else if (this.data[i].status == "Pre-pro Approved" && item[k].status == "Pre-pro Approved") {
            item[k].disable = true;
          } else if (this.data[i].status == "Packaging Concept Submitted" && item[k].status == "Packaging Concept Submitted") {
            item[k].disable = true;
          } else if (this.data[i].status == "Concept Submitted" && item[k].status == "Concept Submitted") {
            item[k].disable = true;
          } else if (this.data[i].status == "Concept Not Approved at All" && item[k].status == "Concept Not Approved at All") {
            item[k].disable = true;
          }
        }
      }
      console.log(item)

      this.license_dropdown_data = item;
    })
  }

  async onSave() {
    if (this.package && !this.license || !this.package && this.license) {
      if (this.form.status == "VALID") {
        console.log(this.package)
        let href = document.location.href.split('/')
        await this.ItemDetailService.AddLicensingStatus({ package: this.package, itemheader_id_fk: href[href.length - 1], feedback: this.form.value.FeedbackController, status: this.form.value.StatusController.status, date: this.form.value.dateModifiedControler.toLocaleString('en-US', { timeZone: 'America/New_York' }), approval: this.form.value.ApprovalController, from: this.form.value.FromController }).subscribe((res: any) => {
          console.log("res", res);
          if (res.statusCode === 200) {
            res.data.package = this.package;
            this.dialogRef.close({ data: res.data })
          } else {
            this._snakcbar.open('Something went wrong', 'close');
          }
        })

      } else {
        this._snakcbar.open('Please fill all fields.', 'close');
      }
    }else{
      this._snakcbar.open('Please select one status.', 'close');
    }
  }

  onCancel() {
    this.dialogRef.close()
  }

}