import { Injectable } from '@angular/core';
import { itemService } from './main.service';
import 'rxjs/add/operator/first';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private itemService:itemService) { }

  async saveToDigitalOceanAndDB(fileName:string, contentType:string, file: File): Promise<Object> {
        const res: any =  await this.itemService.getPreSignedUrl({ fileName, contentType }).first().toPromise();
        const signedUrl = res.signedUrl;
        const headers = new Headers({ 'Content-Type': contentType, 'x-amz-acl': 'public-read' });
        const putConfig = { method: 'PUT', body: file, headers: headers };

        // Upload the file to the cloud storage using the signed URL
        return fetch(signedUrl, putConfig)
  }

  getDigitalOceanPublicURL():string{
    return 'https://dflowbucket.sfo3.digitaloceanspaces.com/';
  }

  resizeImage(file: File, fileName: string): Promise<File> {
    return new Promise(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const image = new Image();
        image.onload = async function () {
          const canvas = document.createElement('canvas');
          canvas.width = 200;
          canvas.height = 200;
          const ctx = canvas.getContext('2d');
          if (ctx != null) {
            ctx.drawImage(image, 0, 0, 200,200);
          }
          const data = canvas.toDataURL('image/jpeg', 1);
          const response = await fetch(data);
          const blob = await response.blob();
          const file = new File([blob], fileName, { type: "image/jpeg" })
          resolve(file);      
        };
        image.src = reader.result as string;
      }
    });
  }
}
