<div mat-dialog-content>
    <div style="padding: 15px;">
        <div>
            <div class="dropzone" vexFileDragNDrop (filesChangeEmitter)="onFileChange($event)">
                <div class="text-wrapper">
                    <div class="centered">
                        <input type="file" name="file" id="file" (change)="onFileChange($event.target.files)">
                        <label for="file"><span class="textLink mat-primary">Select your file</span> or Drop it
                            here!</label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row fileItem" *ngFor="let f of files; let ind = index">
            <div class="col-sm-12 fileItemText" (click)="removeFile(ind);">
                <span>{{f.name.length > 20 ? f.name.slice(0, 20) + '...' : f.name}}</span>
            </div>
        </div>
    </div>
    <section style="
  display: flex;
  gap: 5%;
">
        <button mat-raised-button color="primary" style="width: 100%;margin-bottom: 2%; margin-top: 2%;"
                [disabled]="isLoading"
                (click)="onCancel()">
            Cancel
        </button>
        <button mat-raised-button color="primary" style="width: 100%;margin-bottom: 2%; margin-top: 2%;"
                (click)="onSave()">
            {{isLoading ? 'Uploading...' : 'Save'}}
        </button>
    </section>
</div>