<div style="margin-top: 5%;">
  <mat-tab-group dynamicHeight mat-stretch-tabs style="margin-left: 1%;margin-right: 1%;" (selectedIndexChange)="tabChanged($event)">
    <mat-tab *ngFor="let tab of asyncTabs" [label]="tab.label">
      <div *ngIf="tab.id == '0'">
        <mat-card>
          <div *ngFor="let data of itemData" class="tracking">
            <mat-label><span style="font-weight: bold;">Style #:</span> {{data.item_num_id}}</mat-label>
            <mat-label><span style="font-weight: bold;">Design #:</span> {{data.dsn_ref_num}}</mat-label>
            <mat-label><span style="font-weight: bold;">Display desc :</span>{{data.item_displ_descr_name}}</mat-label>
            <mat-label><span style="font-weight: bold;">Division code :</span> {{data.division_name ||
              "EDGEHOME"}}</mat-label>
            <mat-label><span style="font-weight: bold;">Licensing status :</span> {{data.udf_freeform_01}}</mat-label>
            <mat-label><span style="font-weight: bold;">Production status :</span> {{data.udf_freeform_02}}</mat-label>
          </div>
        </mat-card>
        <br />
        <div>
          <div class="item-detail-card">
            <div class="image-viewer">
              <mat-card class="item-detail-img-card image-mat">
                <image-upload [itemDetailId]="itemDetailId"></image-upload>
              </mat-card>
              
              <div style="padding-top: 1rem;">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-label>View Comments</mat-label>
                    </mat-expansion-panel-header>
                    <mat-card>
                      <view-notes-dialog [itemId]="itemDetailId"></view-notes-dialog>
                    </mat-card>
                  </mat-expansion-panel>
                </mat-accordion>
                <div style="margin-top: 2%;"></div>
<!--                <mat-card style="
                display: flex;
                justify-content: space-between;
                align-items: baseline;">

                  <mat-label>PowerPoint</mat-label>
                  <div style="display: flex;
                  justify-content: flex-end;
                  flex-direction: row;
                  width: fit-content;
                  gap: 1rem;">
                    <button *ngIf="powerpointDlBTN === true" mat-raised-button color="primary">Download</button>
                    <button mat-raised-button color="primary" (click)="uploadFile('powerpoint')">Upload</button>
                    <button mat-raised-button color="primary" (click)="openViewUploadedFilesDialog('techpack')">View
                      Uploaded Files</button>
                  </div>
                </mat-card>
                <br />-->
                <mat-card style="
                display: flex;
                justify-content: space-between;
                align-items: baseline;">
                  <mat-label>Tech Pack</mat-label>
                  <div style="display: flex;
                  justify-content: flex-end;
                  flex-direction: row;
                  width: fit-content;
                  gap: 1rem;">
                    <button *ngIf="techpacktDlBTN === true" mat-raised-button color="primary">Download</button>
                    <button mat-raised-button color="primary" (click)="uploadFile('techpack')">Upload</button>
                    <button mat-raised-button color="primary" (click)="openViewUploadedFilesDialog('techpack')">View
                      Uploaded Files</button>
                  </div>
                </mat-card>
              </div>
            </div>
              <div style="margin-top: 5%;">
                <!-- <div *ngFor="let data of attachments">
                  <mat-card>{{data.item_attachment_fileName}}</mat-card>
                <br />
                </div> -->

              </div>
              <div style="margin-top: 5%;">
                <!-- <div *ngFor="let data of attachments">
                  <mat-card>{{data.item_attachment_fileName}}</mat-card>
                  <br />
                </div> -->

              </div>
              <div style="width: 100%">
                <mat-card *ngIf="!editing" class="col-xxl-auto">
                  <div class="edit" style=" display: flex; flex-direction: column; ">
                    <section>
                      <span style="font-weight: bold;">Active Status :</span>
                      <p> {{selectedActiveStatus?.title}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Item Description :</span>
                      <p> {{itemData[0]?.item_descr_name}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Item Content :</span>
                      <p>{{itemData[0]?.item_content}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Material :</span>
                      <p> {{selectedMaterial?.title}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Construction :</span>
                      <p> {{selectedConstruction?.title}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Feature :</span>
                      <p> {{selectedFeature?.title}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Size :</span>
                      <p> {{selectedSize?.title}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Licensor :</span>
                      <p> {{selectedLicensor?.title}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Property :</span>
                      <p> {{selectedProperty?.title}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Sub Format :</span>
                      <p> {{selectedFormat?.title || null}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Brand assurance :</span>
                      <p> {{itemData[0]?.udf_freeform_03}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Product manager :</span>
                      <p> {{selectedProductManager?.title}}</p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Cust designed :</span>
                      <p></p>
                    </section>
                    <section>
                      <span style="font-weight: bold;">Season code :</span>
                      <p>{{itemData[0]?.season_code_fk}}</p>
                    </section>
                  </div>
                </mat-card>
                <mat-card *ngIf="editing" class="col-xxl-auto" style="width:100%;">

                  <mat-tab-group dynamicHeight mat-stretch-tabs>
                    <mat-tab label="First">

                      <div *ngFor="let data of itemData" class="item-detail-div">
                        <div style="width: 100%;position: absolute;">

                          <section style="display: flex;justify-content: space-evenly;margin-bottom: 5%;">
                            <section class="item-detail-section" style="font-size: smaller;">
                              <mat-label>Item Description : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section"
                                style="width: 30vw;">
                                <input #item_descr_name matInput type="text" [(ngModel)]="data.item_descr_name">
                                <button *ngIf="item_descr_name.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="item_descr_name.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                          </section>

                        </div>

                        <div class="item-detail-div-divider-firstTap item-detail-top-div">

                          <div class="item-detail-div-split">
                            <section class="item-detail-section">
                              <mat-label>Item Content : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <input #item_content matInput type="text" [(ngModel)]="data.item_content">
                                <button *ngIf="item_content.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="item_content.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Material : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedMaterial">
                                  <mat-option *ngFor="let value of materialOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Construction : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedConstruction">
                                  <mat-option *ngFor="let value of constructionOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Feature : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedFeature">
                                  <mat-option *ngFor="let value of featureOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Size : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedSize">
                                  <mat-option *ngFor="let value of sizeOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Licensor : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedLicensor">
                                  <mat-option *ngFor="let value of licensorOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                          </div>
                        </div>
                        <div class="item-detail-div-divider-firstTap">
                          <div class="item-detail-div-split">

                            <section class="item-detail-section">
                              <mat-label>Property : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedProperty">
                                  <mat-option *ngFor="let value of propertyOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Sub Format : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedFormat">
                                  <mat-option *ngFor="let value of formatOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>

                            <section class="item-detail-section">
                              <mat-label>Brand assurance : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <input #udf_freeform_03 matInput type="text" [(ngModel)]="data.udf_freeform_03">
                                <button *ngIf="udf_freeform_03.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udf_freeform_03.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Product manager : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedProductManager">
                                  <mat-option *ngFor="let value of productManagerOption" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Cust designed : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select>
                                  <mat-option *ngFor="let value of data.udf_freeform_05" [value]="value">
                                    {{value}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Season code : </mat-label>
                              <mat-form-field appearance="outline" class="firstTab_item-detail-input-section">
                                <mat-select [(ngModel)]="selectedSeason">
                                  <mat-option *ngFor="let value of seasonptions" [value]="value">
                                    {{value.seasonDesc}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                          </div>
                        </div>


                      </div>
                    </mat-tab>
                    <mat-tab label="Second">
                      <div *ngFor="let data of itemData" class="item-detail-div">
                        <div>
                          <div class="item-detail-div-split">
                            <section class="item-detail-section">
                              <mat-label>Active Status : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select [(ngModel)]="selectedActiveStatus">
                                  <mat-option *ngFor="let value of yesNoOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Discontinued status : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select [(ngModel)]="selectedDiscountStatus">
                                  <mat-option *ngFor="let value of yesNoOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Item Width : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #item_width_size matInput type="text" [(ngModel)]="data.item_width_size">
                                <button *ngIf="item_width_size.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="item_width_size.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Item Depth : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #item_depth_size matInput type="text" [(ngModel)]="data.item_depth_size">
                                <button *ngIf="item_depth_size.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="item_depth_size.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Item Weight : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #item_weight_size matInput type="text" [(ngModel)]="data.item_weight_size">
                                <button *ngIf="item_weight_size.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="item_weight_size.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Measurement UOM : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #uom_size_fk matInput type="text" [(ngModel)]="data.uom_size_fk">
                                <button *ngIf="uom_size_fk.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="uom_size_fk.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Weight UOM : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #uom_weight_fk matInput type="text" [(ngModel)]="data.uom_weight_fk">
                                <button *ngIf="uom_weight_fk.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="uom_weight_fk.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                          </div>
                        </div>
                        <div class="item-detail-div-divider">
                          <div class="item-detail-div-split">
                            <section class="item-detail-section">
                              <mat-label>Item Note : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #item_note matInput type="text" [(ngModel)]="data.item_note">
                                <button *ngIf="item_note.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="item_note.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Artist : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select [(ngModel)]="selectedArtist">
                                  <mat-option *ngFor="let value of artistOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Packaging type : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select [(ngModel)]="selectedPackageType">
                                  <mat-option *ngFor="let value of packageTypeOptions" [value]="value">
                                    {{value}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Art source : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select [(ngModel)]="selectedArtSource">
                                  <mat-option *ngFor="let value of artSourceOptions" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Style guide : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select [(ngModel)]="selectedProperty">
                                  <mat-option *ngFor="let value of data.udf_merchgroup11_fk" [value]="value">
                                    {{value.title}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Standard cost : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #item_cost_ext matInput type="text" [(ngModel)]="data.item_cost_ext">
                                <button *ngIf="item_cost_ext.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="item_cost_ext.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Earliest on shelf : </mat-label>
                              <mat-form-field class="item-detail-input-section" appearance="outline">
                                <input matInput [matDatepicker]="udf_date01" [formControl]="dates[0]">
                                <mat-datepicker-toggle matSuffix [for]="udf_date01"></mat-datepicker-toggle>
                                <mat-datepicker #udf_date01></mat-datepicker>
                              </mat-form-field>
                            </section>
                          </div>
                        </div>
                        <div class="item-detail-div-divider">
                          <div class="item-detail-div-split">
                            <section class="item-detail-section">
                              <mat-label>Test report Recv date : </mat-label>
                              <mat-form-field class="item-detail-input-section" appearance="outline">
                                <input matInput [matDatepicker]="udf_date02" [formControl]="dates[1]">
                                <mat-datepicker-toggle matSuffix [for]="udf_date02"></mat-datepicker-toggle>
                                <mat-datepicker #udf_date02></mat-datepicker>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Photography Recv date : </mat-label>
                              <mat-form-field class="item-detail-input-section" appearance="outline">
                                <input matInput [matDatepicker]="udf_date03" [formControl]="dates[2]">
                                <mat-datepicker-toggle matSuffix [for]="udf_date03"></mat-datepicker-toggle>
                                <mat-datepicker #udf_date03></mat-datepicker>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Price Tickets date : </mat-label>
                              <mat-form-field class="item-detail-input-section" appearance="outline">
                                <input matInput [matDatepicker]="udf_date04" [formControl]="dates[2]">
                                <mat-datepicker-toggle matSuffix [for]="udf_date04"></mat-datepicker-toggle>
                                <mat-datepicker #udf_date04></mat-datepicker>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Mfg Lead Time : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #mfg_lead_time matInput type="text" [(ngModel)]="data.mfg_lead_time">
                                <button *ngIf="mfg_lead_time.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="mfg_lead_time.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>

                            <section class="item-detail-section">
                              <mat-label>Vendor : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select [(ngModel)]="selectedVendor">
                                  <mat-option *ngFor="let value of vendors" [value]="value">
                                    {{value.factory_nickname}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>

                            <section class="item-detail-section">
                              <mat-label>Fty Sent for Testing : </mat-label>
                              <mat-form-field class="item-detail-input-section" appearance="outline">
                                <input matInput [matDatepicker]="udf_date05" [formControl]="dates[3]">
                                <mat-datepicker-toggle matSuffix [for]="udf_date05"></mat-datepicker-toggle>
                                <mat-datepicker #udf_date05></mat-datepicker>
                              </mat-form-field>
                            </section>
                          </div>
                          <div *ngFor="let data of itemData" class="item-detail-div">
                            <div class="item-detail-div-split">
                              <section class="item-detail-section">
                                <mat-label>fty Sent for Photo : </mat-label>
                                <mat-form-field class="item-detail-input-section" appearance="outline">
                                  <input matInput [matDatepicker]="udf_date06" [formControl]="dates[4]">
                                  <mat-datepicker-toggle matSuffix [for]="udf_date06"></mat-datepicker-toggle>
                                  <mat-datepicker #udf_date06></mat-datepicker>
                                </mat-form-field>
                              </section>
                            </div>
                        </div>
                        </div>
                      </div>
                    </mat-tab>

                    <mat-tab label="Third">
                      <div *ngFor="let data of itemData" class="item-detail-div">
                        <div>
                          <div class="item-detail-div-split">
                            <section class="item-detail-section">
                              <mat-label>Item Price A : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udf_item_priceA matInput type="text" [(ngModel)]="data.udf_item_priceA">
                                <button *ngIf="udf_item_priceA.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udf_item_priceA.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Item Price B : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udf_item_priceB matInput type="text" [(ngModel)]="data.udf_item_priceB">
                                <button *ngIf="udf_item_priceB.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udf_item_priceB.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Item Price C : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udf_item_priceC matInput type="text" [(ngModel)]="data.udf_item_priceC">
                                <button *ngIf="udf_item_priceC.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udf_item_priceC.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Item Price D : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udf_item_priceD matInput type="text" [(ngModel)]="data.udf_item_priceD">
                                <button *ngIf="udf_item_priceD.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udf_item_priceD.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Carton Length : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #carton_length_size matInput type="text" [(ngModel)]="data.carton_length_size">
                                <button *ngIf="carton_length_size.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="carton_length_size.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Carton Width : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #carton_width_size matInput type="text" [(ngModel)]="data.carton_width_size">
                                <button *ngIf="carton_width_size.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="carton_width_size.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Carton Depth : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #carton_depth_size matInput type="text" [(ngModel)]="data.carton_depth_size">
                                <button *ngIf="carton_depth_size.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="carton_depth_size.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Carton Weight : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #carton_weight_size matInput type="text" [(ngModel)]="data.carton_weight_size">
                                <button *ngIf="carton_weight_size.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="carton_weight_size.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>

                          </div>
                        </div>
                        <div class="item-detail-div-divider">
                          <div class="item-detail-div-split">
                            <section class="item-detail-section">
                              <mat-label>Selling price : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #sellingPrice matInput type="text" [(ngModel)]="data.selling_price">
                                <button *ngIf="sellingPrice.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="sellingPrice.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Retail price : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #retailPrice matInput type="text" [(ngModel)]="data.retail_price">
                                <button *ngIf="retailPrice.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="retailPrice.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Royalty Code : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #royalty_code_fk matInput type="text" [(ngModel)]="data.royalty_code_fk">
                                <button *ngIf="royalty_code_fk.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="royalty_code_fk.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Sales Person :</mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select [(ngModel)]="selectedSalesPerson">
                                  <mat-option *ngFor="let value of salesperOption" [value]="value">
                                    {{value}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Mold Paid Inv : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udf_freeform_07 matInput type="text" [(ngModel)]="data.udf_freeform_07">
                                <button *ngIf="udf_freeform_07.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udf_freeform_07.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Contr Smpl PO :</mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select>
                                  <mat-option *ngFor="let value of data.udf_freeform_08" [value]="value">
                                    {{value}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Annu Smpl PO :</mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <mat-select>
                                  <mat-option *ngFor="let value of data.udf_freeform_09" [value]="value">
                                    {{value}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Inner Pack Qty : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #innerpack_qty matInput type="text" [(ngModel)]="data.innerpack_qty">
                                <button *ngIf="innerpack_qty.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="innerpack_qty.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                          </div>
                        </div>
                        <div class="item-detail-div-divider">
                          <div class="item-detail-div-split">
                            <section class="item-detail-section">
                              <mat-label>UDF Int 01 : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udf_int01 matInput type="text" [(ngModel)]="data.udf_int01">
                                <button *ngIf="udf_int01.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udf_int01.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>UDF Int 02 : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udf_int02 matInput type="text" [(ngModel)]="data.udf_int02">
                                <button *ngIf="udf_int02.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udf_int02.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Created Time : </mat-label>
                              <mat-form-field class="item-detail-input-section" appearance="outline">
                                <input matInput disabled [matDatepicker]="created_time_date" [formControl]="dates[5]">
                                <mat-datepicker-toggle matSuffix [for]="created_time_date"></mat-datepicker-toggle>
                                <mat-datepicker #created_time_date></mat-datepicker>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>Mod User : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udfnum01 matInput disabled type="text" [(ngModel)]="data.mod_user_fk">
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>UDF Num 01 : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #udfnum01 matInput type="text" [(ngModel)]="data.udfnum01">
                                <button *ngIf="udfnum01.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="udfnum01.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>
                            <section class="item-detail-section">
                              <mat-label>HTS Number : </mat-label>
                              <mat-form-field appearance="outline" class="item-detail-input-section">
                                <input #hts_num_ext_fk matInput type="text" [(ngModel)]="data.hts_num_ext_fk">
                                <button *ngIf="hts_num_ext_fk.value" matSuffix mat-icon-button aria-label="Clear"
                                  (click)="hts_num_ext_fk.value = ''">
                                  <mat-icon>close</mat-icon>
                                </button>
                              </mat-form-field>
                            </section>

                          </div>
                        </div>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </mat-card>
                <div style="padding: 1rem;display: flex;gap: 5%;" *ngIf="editing">
                  <button mat-raised-button color="primary" style=" width: 100%; " (click)="onSave();">Save</button>
                  <button mat-raised-button color="primary" style=" width: 100%; "
                    (click)="editing=false">Close</button>
                </div>
                <div style="padding-top: 1rem;display: flex;flex-direction: column;" *ngIf="!editing">
                  <button mat-raised-button color="primary" style=" width: 100%; "
                    (click)="editing = true">Edit</button>
                  <br />
                  <button mat-raised-button color="primary" style=" width: 100%; " (click)="onStyleNumber()">Request a
                    style number</button>
                </div>
            </div>
          </div>

        </div>

      </div>

      <div *ngIf="tab.id == '1'">
        <license-tracking [showLatest]="false" [gridClass]="'ag-theme-alpine'" [itemDetailId]="itemDetailId"
          [itemData]="itemData[0]" [selectedLicensor]="selectedLicensor?.title"></license-tracking>
      </div>
      <!-- <div *ngIf="tab.id == '2'">
        <mat-card>Tracking</mat-card>
        <br />
        <mat-card>Pricing</mat-card>
      </div>
      <div *ngIf="tab.id == '3'">
        <mat-card>
          <prod-tracking [showLatest]="true" [gridClass]="'small-grid'" [itemDetailId]="itemDetailId"></prod-tracking>
        </mat-card>
        <br />
        <mat-card>
          <div>
            <prod-tracking [showLatest]="false" [gridClass]="'ag-theme-alpine'"
              [itemDetailId]="itemDetailId"></prod-tracking>
          </div>
        </mat-card>
      </div> -->
      <div *ngIf="tab.id == '2'">
        <mat-card>
          <div *ngFor="let data of itemData" class="tracking">
            <mat-label><span style="font-weight: bold;">Style #:</span> {{data.item_num_id}}</mat-label>
            <mat-label><span style="font-weight: bold;">Design #:</span> {{data.dsn_ref_num}}</mat-label>
            <mat-label><span style="font-weight: bold;">Display desc :</span>{{data.item_displ_descr_name}}</mat-label>
            <mat-label><span style="font-weight: bold;">Division code :</span> {{data.division_name ||
              "EDGEHOME"}}</mat-label>
            <mat-label><span style="font-weight: bold;">Licensing status :</span> {{data.udf_freeform_01}}</mat-label>
            <mat-label><span style="font-weight: bold;">Production status :</span> {{data.udf_freeform_02}}</mat-label>
          </div>
        </mat-card>
        <vex-calendar *ngIf="showCalendar"></vex-calendar>
      </div>
      <div style="display: flex;justify-content: center;" *ngIf="tab.id == '6'">
        <mat-card style="min-height:calc(65em - 170px);min-width: calc(100% - 12%);">

          <div class="chat">
            <div class="first messages">
              <div class="message last">
                <div>
                  <mat-label>
                    Miusov, as a man man of breeding and deilcacy, could not but feel some inwrd qualms, when he reached
                    the Father Superior's with Ivan: he felt ashamed of havin lost his temper. He felt that he ought to
                    have disdaimed that despicable wretch, Fyodor Pavlovitch, too much to have been upset by him in
                    Father
                    Zossima's cell, and so to have forgotten himself. "Teh monks were not to blame, in any case," he
                    reflceted, on the steps. "And if they're decent people here (and the Father Superior, I understand,
                    is
                    a nobleman) why not be friendly and courteous withthem? I won't argue, I'll fall in with everything,
                    I'll win them by politness, and show them that I've nothing to do with that Aesop, thta buffoon,
                    that
                    Pierrot, and have merely been takken in over this affair, just as they have."
                  </mat-label>
                </div>
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="first-account_circle">account_circle</mat-icon>
              </div>
            </div>
            <div class="second messages">
              <div class="message">
                Hey!
              </div>
              <div class="message">
                You there?
              </div>
              <div class="message last">
                Hello, how's it going?
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="second-account_circle">account_circle</mat-icon>
              </div>
            </div>
            <div class="first messages">
              <div class="message">
                Hello!
              </div>
              <div class="message">
                Great thanks!
              </div>
              <div class="message last">
                <div>
                  <mat-label>
                    How about you?
                  </mat-label>
                </div>
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="first-account_circle">account_circle</mat-icon>
              </div>
            </div>
          </div>

        </mat-card>
      </div>
      <div style="display: flex;justify-content: center;" *ngIf="tab.id == '7'">
        <mat-card style="min-height:calc(65em - 170px);min-width: calc(100% - 12%);">

          <div class="chat">
            <div class="first messages">
              <div class="message last">
                <div>
                  <mat-label>
                    Miusov, as a man man of breeding and deilcacy, could not but feel some inwrd qualms, when he reached
                    the Father Superior's with Ivan: he felt ashamed of havin lost his temper. He felt that he ought to
                    have disdaimed that despicable wretch, Fyodor Pavlovitch, too much to have been upset by him in
                    Father
                    Zossima's cell, and so to have forgotten himself. "Teh monks were not to blame, in any case," he
                    reflceted, on the steps. "And if they're decent people here (and the Father Superior, I understand,
                    is
                    a nobleman) why not be friendly and courteous withthem? I won't argue, I'll fall in with everything,
                    I'll win them by politness, and show them that I've nothing to do with that Aesop, thta buffoon,
                    that
                    Pierrot, and have merely been takken in over this affair, just as they have."
                  </mat-label>
                </div>
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="first-account_circle">account_circle</mat-icon>
              </div>
            </div>
            <div class="second messages">
              <div class="message">
                Hey!
              </div>
              <div class="message">
                You there?
              </div>
              <div class="message last">
                Hello, how's it going?
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="second-account_circle">account_circle</mat-icon>
              </div>
            </div>
            <div class="first messages">
              <div class="message">
                Hello!
              </div>
              <div class="message">
                Great thanks!
              </div>
              <div class="message last">
                <div>
                  <mat-label>
                    How about you?
                  </mat-label>
                </div>
              </div>
              <div class="chat-div-account_circle" style="display: flex;">
                <mat-icon class="first-account_circle">account_circle</mat-icon>
              </div>
            </div>
          </div>

        </mat-card>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
