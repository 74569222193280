<div style="padding:1%;">
  <section [formGroup]="form">
    <span style="display: flex;justify-content: space-around;">Current Status : {{data.udf_freeform_01}} </span>
    <div style="
    display: flex;
    flex-direction: row;
    justify-content: space-around;
">

      <mat-checkbox [(ngModel)]="license" formControlName="licenseController">License</mat-checkbox>
      <mat-checkbox [(ngModel)]="package" formControlName="packageController">Package</mat-checkbox>
      <!--  <mat-checkbox [(ngModel)]="license" formControlName="license">License</mat-checkbox> -->
    </div>

    <mat-form-field appearance="outline">
      <mat-label>From:</mat-label>
      <mat-select formControlName="FromController">
        <mat-option *ngFor="let value of fromData" [value]="value" (click)="onSelectFrom(value)">
          {{value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Phases of license approval:</mat-label>
      <mat-select formControlName="StatusController">
        <mat-select-trigger>
          {{form.controls['StatusController']?.value?.status}}
        </mat-select-trigger>
        <div>
          <div style="display: flex;flex-direction: row;justify-content: space-around;position: sticky;gap: 6vw;">
            <span>Phase</span>
            <span>Status</span>
          </div>
          <mat-option *ngFor="let value of license_dropdown_data" [value]="value" [disabled]="value.disable">
            <!--table-->
            <div style="
          min-width: 500px;
      ">
              <!--row-->
              <div [matTooltip]="value.explanation" style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        ">
                <!--left column-->
                <div>
                  {{value.phase}}
                </div>
                <!--right column-->
                <div>
                  {{value.status}}
                </div>
              </div>
            </div>

          </mat-option>
        </div>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Feedback : </mat-label>
      <input type="text" formControlName="FeedbackController" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Date : </mat-label>
      <input #DateModified matInput [matDatepicker]="picker" formControlName="dateModifiedControler"
        [formControl]="dateModifiedControler">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </section>
  <section style="
  display: flex;
  gap: 5%;
">
    <button mat-raised-button color="primary" style="width: 100%;margin-bottom: 2%; margin-top: 2%;"
      (click)="onCancel()">
      Cancel
    </button>
    <button mat-raised-button color="primary" style="width: 100%;margin-bottom: 2%; margin-top: 2%;" (click)="onSave()">
      Save
    </button>
  </section>
</div>