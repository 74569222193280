<div class="comment">
  <div class="comment-image-container">
    <img src="/assets/img/user/user-icon.png" />
  </div>
  <div class="comment-right-part">
    <div class="comment-content">
      <div class="comment-content">
        <div class="comment-author">{{ comment.user_name }}</div>
        <div class="comment-author-time">{{ comment.inserted_date }}</div>
      </div>
    </div>
    <div class="comment-text" [innerHTML]="comment.comment"></div>
    <!-- Check if comment has attachments -->
    <div *ngIf="comment.attachments && comment.attachments.length > 0">
      <!-- Loop through each attachment in the comment's attachments array -->
      <div *ngFor="let attachment of comment.attachments">
        <div>
          <a [attr.href]="attachment.attachment_link" target="_blank">
            <!-- Display the PDF icon only if the attachment_link is present -->
            <i [ngClass]="getIconClass(attachment.item_attachment_fileName)"></i>
            <label class="file-name">{{ attachment.item_attachment_fileName }}</label>
          </a>
        </div>
      </div>
    </div>
    <comment-form 
      *ngIf="isEditing()" 
      submitLabel="Update" 
      [hasCancelButton]="true"
      [initialText]="comment.comment"
      (handleSubmit)="updateComment.emit({ text: $event.text, files: $event.files, commentId: comment.id })"
      (handleCancel)="setActiveComment.emit(null)"
      >
    </comment-form>
  <div class="comment-actions">
    <div 
      *ngIf="canReply" 
      class="comment-action" 
      (click)="setActiveComment.emit({ id: comment.id, type: activeCommentType.replying })">
      Reply
    </div>
    <div 
      *ngIf="canEdit" 
      class="comment-action" 
      (click)="setActiveComment.emit({ id: comment.id, type: activeCommentType.editing })">
      Edit
    </div>
    <div 
      *ngIf="canDelete" 
      class="comment-action" 
      (click)="deleteComment.emit(comment.id)">
      Delete
    </div>
  </div>  
    <comment-form 
      [comment]="comment" 
      *ngIf="isReplying()" 
      submitLabel="Reply" 
      [hasCancelButton]="true"
      (handleSubmit)="addComment.emit({ text: $event.text, files: $event.files, parentId: replyId})"
      (handleCancel)="setActiveComment.emit(null)"
      >
    </comment-form>
    <div class="replies" *ngIf="replies.length > 0">
      <comment 
        *ngFor="let reply of replies" 
        [comment]="reply" 
        (setActiveComment)="setActiveComment.emit($event)"
        [activeComment]="activeComment" 
        (updateComment)="updateComment.emit($event)"
        (deleteComment)="deleteComment.emit($event)" 
        (addComment)="addComment.emit($event)"
        [parentId]="comment.id"
        [replies]="[]" 
        [currentUserId]="currentUserId">
      </comment>
    </div>
  </div>
</div>
