import { Component, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { itemService } from "../../../helpers/services/main.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ItemDetailService } from '../../../helpers/services/item_detail.service';

@Component({
  selector: 'vex-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent {
  public files: any[] = [];
  isLoading = false;

  constructor(private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<UploadFileComponent>,
    private itemService: itemService,
    private http: HttpClient,
    private itemDetailService: ItemDetailService,
    @Inject(MAT_DIALOG_DATA) public data: { itemId: number, value: string }) {
  }

  onFileChange(pFileList: File[]) {
    this.files = Object.keys(pFileList).map(key => pFileList[key]);
  }

  onSave() {
    console.log(this.data.value)
    if (this.isLoading) { return }
    if (!this.files.length) {
      this._snackBar.open("You need to choose file first!", 'Close', {
        duration: 2000,
      });
      return;
    }
    const file = this.files[0];
    const contentType = file.type;
    const fileName = 'images-' + `${new Date().getTime()}-${file.name.toLowerCase()}`
    this.isLoading = true;
    this.itemService
      .getPreSignedUrl({ fileName, contentType })
      .subscribe((res: { signedUrl: string }) => {
        const signedUrl = res.signedUrl
        const headers = new Headers({ 'Content-Type': contentType, 'x-amz-acl': 'public-read' });
        const putConfig = { method: 'PUT', body: file, headers: headers }
        fetch(signedUrl, putConfig)
          .then(output => {
              const body = this.data.value === 'rfq'
              ? { flag: 'rfq-update', fileName }
              : {
                  flag: 'item-attachment-create',
                  item_num_id_fk: this.data.itemId,
                  attachment_type: contentType,
                  attachment_display_name: contentType.split("/")[0],
                  fileName
                };

              this.itemService
                .saveTechLink(body, this.data.itemId)
                .pipe(take(1))
                .subscribe((res: { sharedLink: string }) => {
                  this.isLoading = false;
                  this.dialogRef.close(res.sharedLink);
              });
          }).catch(error => console.error('Error uploading file:', error))
      })
  }

  onCancel() {
    this.dialogRef.close()
  }

  removeFile(pIndex): void {
    this.deleteFromArray(pIndex);
  }

  deleteFromArray(index) {
    this.files.splice(index, 1);
  }
}
