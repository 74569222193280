<form [formGroup]="form" (ngSubmit)="onSubmit()" class="comments-form">
    <quill-editor [styles]="{ flex: '1 1 auto', display: 'flex', 'flex-direction': 'column', height: '100px' }"
        class="flex-auto flex flex-col" placeholder="Write Comments" formControlName="title">
        <div quill-editor-toolbar>
            <span class="ql-formats">
                <!-- Text Formatting Options -->
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>

                <!-- Block Formatting Options -->
                <button class="ql-blockquote"></button>
                <!-- <button class="ql-code-block"></button> -->

                <!-- List Options -->
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>

                <!-- Header Dropdown -->
                <select class="ql-header">
                    <option value="1"></option>
                    <option value="2"></option>
                    <option value="3"></option>
                    <option value="4"></option>
                    <option value="5"></option>
                    <option value="6"></option>
                    <option value="false"></option>
                </select>

                <!-- Color Options -->
                <button class="ql-color"></button>
                <button class="ql-background"></button>

                <!-- Clear Formatting -->
                <button class="ql-clean"></button>

                <!-- Insert Options -->
                <!-- <button class="ql-link"></button>
                <button class="ql-image"></button>
                <button class="ql-video"></button> -->

                <!-- HTML for the custom file input control -->
                <label class="custom-file-input-label">
                    <!-- Add Font Awesome 5.x solid paperclip icon using CSS classes -->
                    <i class="fas fa-paperclip vertical-paperclip"></i>
                    <!-- File input control -->
                    <input type="file" class="custom-file-input" #fileInput  (change)="onFileSelected($event)" multiple>
                </label>
            </span>

        </div>
    </quill-editor>
    <br>
    <button class="comment-form-button" 
            type="submit" 
            [disabled]="form.invalid">
        {{ submitLabel }}
    </button>

    <button *ngIf="hasCancelButton" 
            type="button" 
            class="comment-form-button comment-form-cancel-button"
            (click)="handleCancel.emit()">
        Cancel
    </button>
</form>
