import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable()
export class AuthService {
    serverAddress = environment.url;
    constructor(public jwtHelper: JwtHelperService, private http: HttpClient,) { }
    // ...
    setUserAuth(token) {
        token = localStorage.getItem("token")
        const headers = new HttpHeaders()
            .set('Authorization', token)
        return headers
    }

    async isAuthenticated() {
        const token = localStorage.getItem('token');
        // Check whether the token is expired and return
        // true or false
        let isvalid = await this.verifyToken(token)

        return isvalid;
    }
    async verifyToken(token): Promise<boolean> {
        let isvalid = <boolean>await new Promise((resolve, reject) => {
            this.http.get(this.serverAddress + `/verifyToken`, { headers: this.setUserAuth(token) }).subscribe((data: any) => {
                if (data.status == "success" && !this.jwtHelper.isTokenExpired(token)) {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            })
        })
        return isvalid;
    }
}

