import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss']
})
export class CommentFormComponent implements OnInit {
  @Input() submitLabel!: string;
  @Input() hasCancelButton: boolean = false;
  @Input() initialText: string = '';
  @Input() comment: string

  @Output() handleSubmit = new EventEmitter<{ text: string, files?: any[]}>();
  @Output() handleCancel = new EventEmitter<void>();

  @ViewChild('fileInput') fileInput: ElementRef;

  form!: FormGroup;
  public files: any[] = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      title: [this.initialText, Validators.required],
    });
  }

  onFileSelected(event) {
    this.files = [];
    this.files = event.target.files;
  }

  onSubmit(): void {
    console.log(this.fileInput.nativeElement.value)
    this.handleSubmit.emit({ text: this.form.value.title, files: this.files });
    console.log(this.fileInput.nativeElement.value)
    this.form.reset();
  }

  resetFileInput(){
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }
}
