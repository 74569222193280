import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import {
  startOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  parseISO,
  addMinutes,
  format,
} from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { EventColor } from 'calendar-utils';
import { ItemDetailService } from 'src/app/helpers/services/item_detail.service';

@Component({
  selector: 'vex-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  licenseData=[];
  CalendarView = CalendarView;
  refresh = new Subject<void>();
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  activeDayIsOpen: boolean = false;
   colors: Record<string, EventColor> = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
  };

  constructor(private itemDetailService:ItemDetailService){}

  events = []

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }


  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  ngOnInit(): void {
    let href = document.location.href.split('/')
    
    this.itemDetailService.GetLicensingStatus(href[href.length - 1]).subscribe((data: any) => {
      for (let i = 0; data.length > i; i++) {
          this.licenseData.push(data[i]);
    }

    for (let i = 0; this.licenseData.length > i; i++) {
      this.events.push(  {
        start: new Date(this.licenseData[i].date),
        title: this.licenseData[i].status,
        color: { ...this.colors.blue },
        date:format(new Date(this.licenseData[i].date),'MM-dd-yyyy'),
        time:format(new Date(this.licenseData[i].date),'h:mm a'),
        user:this.licenseData[i].moduser,
})
    }
  })
}
}


