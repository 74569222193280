import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ItemDetailService } from 'src/app/helpers/services/item_detail.service';
import decode from 'jwt-decode';
import { ActiveCommentInterface } from '../types/activeComment.interface';
import { CommentInterface } from '../types/commentInterface';
import { itemService } from 'src/app/helpers/services/main.service';
import { take } from 'rxjs/operators';
import { CommentFormComponent } from '../comment-form/comment-form.component';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input() itemHeaderId: number

  comments: CommentInterface[] = [];
  rootComments: CommentInterface[] = [];
  userInfo: any;
  currentUserId: number
  activeComment: ActiveCommentInterface | null = null;
  loading: boolean = false

  @ViewChild(CommentFormComponent) childComponent: CommentFormComponent

  constructor(public itemService: itemService) {
  }

  ngOnInit(): void {
    this.getUserInfo()
    this.getComments()
    this.currentUserId = this.userInfo.userid
    console.log(this.currentUserId)
  }

  getComments() {
    this.itemService.getComments(this.itemHeaderId).subscribe(data => {
      console.log(data)
      this.comments = data.map(item => ({
        id: item.id,
        comment: item.comment,
        item_header_id: item.item_header_id,
        user_id: item.user_id,
        user_name: item.users.length > 0 ? item.users[0].name : '',
        parent_id: item.parent_id,
        inserted_date: this.getFormattedDate(item.inserted_date),
        attachments: item.itemAttachments.map(attachment => ({
          attachment_link: attachment.attachment_link,
          item_attachment_fileName: attachment.item_attachment_fileName
        }))
      }));
    })
  }

  getRootComments(): CommentInterface[] {
    return this.comments.filter((comment) => comment.parent_id === null);
  }

  addComment(form: any) {
    const comment: CommentInterface = {
      comment: form.text,
      item_header_id: this.itemHeaderId,
      user_id: this.userInfo.userid,
      parent_id: form.parentId
    }
    this.itemService.addComment(comment).subscribe(data => {
      const commentId = data.id;
      console.log(form.files)
      if (form.files.length > 0) {
        this.addAttachments(commentId, form.files)
      } else {
        this.getComments()
      }
      this.activeComment = null;
    })
  }

  updateComment(form: any) {
    console.log('updateComment is invoked')
    this.itemService.updateComment(form.commentId, form.text).subscribe(data => {
      if (form.files.length > 0) {
        this.addAttachments(form.commentId, form.files)
      } else {
        this.getComments()
      }
      this.getReplies(form.commentId)
      this.activeComment = null;
    })
  }

  addAttachments(commentId: number, files: any[]) {
    const fileCount = files.length;
    this.loading = true
    let href = document.location.href.split('/');
    const itemId = +href[href.length - 1];

    // Iterate through each file in the files array
    for (let i = 0; i < fileCount; i++) {
      const file: File = files[i];
      const contentType = file.type;
      const fileName = `comments-${new Date().getTime()}-${file.name.toLowerCase()}`;

      // Obtain the pre-signed URL for the current file
      this.itemService.getPreSignedUrl({ fileName, contentType })
        .subscribe((res: { signedUrl: string }) => {
          const signedUrl = res.signedUrl;
          const headers = new Headers({ 'Content-Type': contentType, 'x-amz-acl': 'public-read' });
          const putConfig = { method: 'PUT', body: file, headers: headers };

          // Upload the file to the cloud storage using the signed URL
          fetch(signedUrl, putConfig)
            .then(output => {
              const body = {
                flag: 'comment-create-edit',
                item_num_id_fk: itemId,
                attachment_type: contentType,
                attachment_display_name: contentType.split("/")[0],
                fileName,
                comment_id: commentId
              };
              // Save the file link to your database
              this.itemService.saveTechLink(body, itemId)
                .pipe(take(1))
                .subscribe((res) => {
                  this.getComments()
                  if (i === 0) {
                    this.loading = false
                    this.childComponent.resetFileInput();
                  }
                })
            }).catch(error => {
              console.error('Error uploading file:', error)
              this.loading = false;
            });
        });
    }
  }

  deleteComment(commentId: number): void {
    this.itemService.deleteComment(commentId).subscribe(() => {
      this.comments = this.comments.filter(
        (comment) => comment.id !== commentId
      );
    });
  }

  getReplies(commentId: number): CommentInterface[] {
    return this.comments
      .filter(comment => comment.parent_id === commentId)
      .sort((a, b) => new Date(a.inserted_date).getTime() - new Date(b.inserted_date).getTime());
  }

  setActiveComment(activeComment: ActiveCommentInterface | null): void {
    this.activeComment = activeComment;
  }

  getUserInfo() {
    const token = localStorage.getItem('token');
    if (token) {
      const tokenPayload = <any>decode(token);
      this.userInfo = {
        userid: tokenPayload.user_id,
        name: tokenPayload.name,
        email: tokenPayload.email
      }
    }
  }

  getFormattedDate(inserted_date) {
    // Convert inserted_date to MM/DD/YY HH:MM:SS format
    const dateObj = new Date(inserted_date);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
    const day = dateObj.getDate().toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate
  }
}