import { Component, OnInit, Inject, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

export interface AttachmentListTable {
  id: number
  attachment_display_name: string;
  attachment_link: string;
  attachment_type: string;
  item_num_id_fk: string;
  item_attachment_fileName: string
}

@Component({
  selector: 'vex-view-uploaded-files-dialog',
  templateUrl: './view-uploaded-files-dialog.component.html',
  styleUrls: ['./view-uploaded-files-dialog.component.scss']
})

export class ViewUploadedFilesDialogComponent {

  displayedColumns: string[] = ['id', 'attachment_type', 'item_attachment_fileName'];
  attachments: any = this.data.attachments;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.attachments = new MatTableDataSource(this.data.attachments)
    this.attachments.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  constructor(
    private dialogRef: MatDialogRef<ViewUploadedFilesDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { type: string, attachments: any }
  ) {
    this.attachments.forEach((attachment, index) => {
      attachment.id = index + 1;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
